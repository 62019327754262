import React, {useCallback} from 'react';
import Table from 'components/core/Table';
import * as S from './Table.styles';
import {useDispatch, useSelector} from 'react-redux';
import LoadingFullScreen from 'components/core/LoadingFullScreen/';
import {isPending} from 'lib/core/request';
import {InvestorsActions} from 'store/investors/investors.ducks';
import {formatMoney} from 'lib/core/money';

const TableDefault = () => {
  const dispatch = useDispatch();

  const {
    listInvestors: {
      data: items,
      meta: {totalPages},
    },
    investorsRequests,
  } = useSelector(({investors}) => investors);

  const fetchData = useCallback(
    ({pageSize, pageIndex}) => {
      dispatch(
        InvestorsActions.listInvestors({
          limit: pageSize,
          page: pageIndex + 1,
        }),
      );
    },
    [dispatch],
  );

  const columns = React.useMemo(
    () => [
      {
        Header: `ID`,
        accessor: 'id',
        maxWidth: 20,
      },
      {
        Header: `Investor's name`,
        accessor: 'investor_name',
        minWidth: 200,
      },
      {
        Header: 'E-mail',
        accessor: 'user.email',
        minWidth: 150,
      },
      {
        Header: 'Value',
        accessor: 'investor_ranking',
        minWidth: 100,
        Cell: (props) => formatMoney(props.value),
      },
      {
        Header: 'Super Angel',
        accessor: 'super_angel',
        minWidth: 50,
        Cell: (props) => (props.value ? 'Yes' : 'No'),
      },
    ],
    [],
  );

  return (
    <S.TableContent>
      {isPending(investorsRequests.LIST_ENTERPRISES) && <LoadingFullScreen />}
      {/* <S.Header>
        <S.Title>Investors</S.Title>
        <S.FilterContainer>
          <Search
            placeholder="Search for investor name"
            onChange={onChangeSearch}
          />
        </S.FilterContainer>
      </S.Header> */}
      <Table
        columns={columns}
        data={items}
        pageCount={totalPages}
        fetchData={fetchData}
        showTitle="investors"
      />
    </S.TableContent>
  );
};

export default TableDefault;
