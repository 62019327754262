import styled from 'styled-components';
import tokens from 'assets/styles/tokens';

export const NavTop = styled.div`
  background-color: ${tokens.colors.primaryHeaderColor};
  height: 120px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 8px 24px rgba(11, 0, 21, 0.04);
`;

export const Logo = styled.img`
  height: 60px;
  filter: brightness(0) invert(1);
`;
