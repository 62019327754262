import {createActions, createReducer} from 'reduxsauce';
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
} from 'constants/request';

export const {
  Types: InvestorsTypes,
  Creators: InvestorsActions,
} = createActions({
  listInvestors: ['params'],
  listInvestorsSuccess: ['payload'],
  createInvestor: ['payload'],
  createInvestorSuccess: ['payload'],
  ranking: ['params'],
  rankingSuccess: ['payload'],
  investorsRequestFailure: ['error'],
});

const INITIAL_STATE = {
  listInvestors: {
    items: [],
    meta: {
      totalPages: 0,
      itemsPerPage: 10,
      currentPage: 1,
    },
  },
  ranking: {
    items: [],
    meta: {
      totalPages: 0,
      itemsPerPage: 10,
      currentPage: 1,
    },
  },
  error: null,
  investorsRequests: {
    [InvestorsTypes.LIST_INVESTORS]: REQUEST_NOT_STARTED,
    [InvestorsTypes.RANKING]: REQUEST_NOT_STARTED,
    [InvestorsTypes.CREATE_INVESTOR]: REQUEST_NOT_STARTED,
  },
};

const listInvestors = (state) => ({
  ...state,
  investorsRequests: {
    ...INITIAL_STATE.investorsRequests,
    [InvestorsTypes.LIST_INVESTORS]: REQUEST_PENDING,
  },
});
const listInvestorsSuccess = (state, {payload}) => ({
  ...state,
  listInvestors: payload,
  investorsRequests: {
    ...INITIAL_STATE.investorsRequests,
    [InvestorsTypes.LIST_INVESTORS]: REQUEST_RESOLVED,
  },
});

const createInvestor = (state) => ({
  ...state,
  radioRequests: {
    ...INITIAL_STATE.radioRequests,
    [InvestorsTypes.CREATE_INVESTOR]: REQUEST_PENDING,
  },
});
const createInvestorSuccess = (state) => ({
  ...state,
  radioRequests: {
    ...INITIAL_STATE.radioRequests,
    [InvestorsTypes.CREATE_INVESTOR]: REQUEST_RESOLVED,
  },
});

const ranking = (state) => ({
  ...state,
  investorsRequests: {
    ...INITIAL_STATE.investorsRequests,
    [InvestorsTypes.LIST_INVESTORS]: REQUEST_PENDING,
  },
});
const rankingSuccess = (state, {payload}) => ({
  ...state,
  ranking: payload,
  investorsRequests: {
    ...INITIAL_STATE.investorsRequests,
    [InvestorsTypes.LIST_INVESTORS]: REQUEST_RESOLVED,
  },
});
const investorsRequestFailure = (state, {error: {response}}) => ({
  ...state,
  error: response,
  investorsRequests: {
    ...state.investorsRequests,
    [InvestorsTypes.GET_INVESTORS]: REQUEST_REJECTED,
    [InvestorsTypes.LIST_INVESTORS]: REQUEST_REJECTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [InvestorsTypes.LIST_INVESTORS]: listInvestors,
  [InvestorsTypes.LIST_INVESTORS_SUCCESS]: listInvestorsSuccess,
  [InvestorsTypes.CREATE_INVESTOR]: createInvestor,
  [InvestorsTypes.CREATE_INVESTOR_SUCCESS]: createInvestorSuccess,
  [InvestorsTypes.RANKING]: ranking,
  [InvestorsTypes.RANKING_SUCCESS]: rankingSuccess,
  [InvestorsTypes.INVESTORS_REQUEST_FAILURE]: investorsRequestFailure,
});
