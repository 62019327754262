import styled from 'styled-components';
import {variant} from 'styled-system';
import tokens from 'assets/styles/tokens';

export const DashboardContent = styled.div`
  padding-top: ${tokens.spacing.lg};
`;
export const Header = styled.div`
  padding-top: ${tokens.spacing.lg};
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
`;
export const Title = styled('div')(
  {
    fontSize: '20px',
    lineHeight: '24px',
    fontFamily: tokens.fonts.bold,
    color: tokens.colors.black01,
    fontWeight: 700,
  },
  variant({
    prop: 'color',
    variants: {
      red: {
        color: tokens.colors.black01,
      },
    },
  }),
);

export const Filter = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-family: ${tokens.fonts.semibold};
  font-weight: 600;
  color: ${tokens.colors.black01};
`;
export const WrapperCard = styled.div`
  margin: -15px;
  ${tokens.media.xs} {
    margin: none;
  }
`;
