import React from 'react';
import {NavbarDefault} from 'components/core/navbar';
import {Container} from 'components/structure/Container';
import {Content} from 'components/structure/Content';
import Table from 'components/contexts/investors/Table';
import Header from 'components/core/Header';
import {history} from 'constants/routes';
import urls from 'constants/urls';

const Investors = () => {
  return (
    <>
      <NavbarDefault active="investors" />
      <Content>
        <Container>
          <Header
            title="Investors"
            subtitle=""
            buttonText="New investor"
            onClick={() => {
              history.push(urls.LINKS.CREATE_INVESTOR);
            }}
          />
          <Table />
        </Container>
      </Content>
    </>
  );
};

export default Investors;
