import {createActions, createReducer} from 'reduxsauce';
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
} from 'constants/request';

export const {
  Types: EnterprisesTypes,
  Creators: EnterprisesActions,
} = createActions({
  listEnterprises: ['params'],
  listEnterprisesSuccess: ['payload'],
  createEnterprise: ['payload'],
  createEnterpriseSuccess: ['payload'],
  rankingEnterprise: ['params'],
  rankingEnterpriseSuccess: ['payload'],
  enterprisesRequestFailure: ['error'],
});

const INITIAL_STATE = {
  listEnterprises: {
    items: [],
    meta: {
      totalPages: 0,
      itemsPerPage: 20,
      currentPage: 1,
    },
  },
  ranking: {
    items: [],
    meta: {
      totalPages: 0,
      itemsPerPage: 20,
      currentPage: 1,
    },
  },
  error: null,
  enterprisesRequests: {
    [EnterprisesTypes.LIST_ENTERPRISES]: REQUEST_NOT_STARTED,
    [EnterprisesTypes.RANKING]: REQUEST_NOT_STARTED,
    [EnterprisesTypes.CREATE_ENTERPRISE]: REQUEST_NOT_STARTED,
  },
};

const listEnterprises = (state) => ({
  ...state,
  enterprisesRequests: {
    ...INITIAL_STATE.enterprisesRequests,
    [EnterprisesTypes.LIST_ENTERPRISES]: REQUEST_PENDING,
  },
});
const listEnterprisesSuccess = (state, {payload}) => ({
  ...state,
  listEnterprises: payload,
  enterprisesRequests: {
    ...INITIAL_STATE.enterprisesRequests,
    [EnterprisesTypes.LIST_ENTERPRISES]: REQUEST_RESOLVED,
  },
});
const createEnterprise = (state) => ({
  ...state,
  radioRequests: {
    ...INITIAL_STATE.radioRequests,
    [EnterprisesTypes.CREATE_ENTERPRISE]: REQUEST_PENDING,
  },
});
const createEnterpriseSuccess = (state) => ({
  ...state,
  radioRequests: {
    ...INITIAL_STATE.radioRequests,
    [EnterprisesTypes.CREATE_ENTERPRISE]: REQUEST_RESOLVED,
  },
});
const rankingEnterprise = (state) => ({
  ...state,
  enterprisesRequests: {
    ...INITIAL_STATE.enterprisesRequests,
    [EnterprisesTypes.RANKING]: REQUEST_PENDING,
  },
});
const rankingEnterpriseSuccess = (state, {payload}) => ({
  ...state,
  ranking: payload,
  enterprisesRequests: {
    ...INITIAL_STATE.enterprisesRequests,
    [EnterprisesTypes.RANKING]: REQUEST_RESOLVED,
  },
});
const enterprisesRequestFailure = (state, {error: {response}}) => ({
  ...state,
  error: response,
  enterprisesRequests: {
    ...state.enterprisesRequests,
    [EnterprisesTypes.LIST_ENTERPRISES]: REQUEST_REJECTED,
    [EnterprisesTypes.RANKING]: REQUEST_REJECTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [EnterprisesTypes.LIST_ENTERPRISES]: listEnterprises,
  [EnterprisesTypes.LIST_ENTERPRISES_SUCCESS]: listEnterprisesSuccess,
  [EnterprisesTypes.CREATE_ENTERPRISE]: createEnterprise,
  [EnterprisesTypes.CREATE_ENTERPRISE_SUCCESS]: createEnterpriseSuccess,
  [EnterprisesTypes.RANKING_ENTERPRISE]: rankingEnterprise,
  [EnterprisesTypes.RANKING_ENTERPRISE_SUCCESS]: rankingEnterpriseSuccess,
  [EnterprisesTypes.ENTERPRISES_REQUEST_FAILURE]: enterprisesRequestFailure,
});
