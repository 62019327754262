/* eslint-disable no-template-curly-in-string */
import React, {useMemo} from 'react';
import {NavbarDefault} from 'components/core/navbar';
import {Container} from 'components/structure/Container';
import {Content} from 'components/structure/Content';
import Header from 'components/core/Header';
import {history} from 'constants/routes';
import Form from 'components/contexts/investors/Form';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import * as yup from 'yup';
import useYupValidationResolver from 'hooks/useYupValidationResolver';
import {InvestorsActions} from 'store/investors/investors.ducks';

const NewInvestor = () => {
  const dispatch = useDispatch();

  const {investorsRequests, error} = useSelector(({investors}) => investors);

  const validationSchema = useMemo(
    () =>
      yup.object({
        name: yup.string().label("Investor's name").required(),
        email: yup.string().email().label('E-mail').required(),
      }),
    [],
  );
  const resolver = useYupValidationResolver(validationSchema);

  const {register, handleSubmit, errors} = useForm({resolver});

  const onSubmit = (data) => {
    dispatch(InvestorsActions.createInvestor(data));
  };
  return (
    <>
      <NavbarDefault active="investors" />
      <Content>
        <Container>
          <Header
            type="back"
            title="Register new investor"
            onClickBack={() => history.goBack()}
          />
          <Form
            register={register}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            error={error}
            errors={errors}
            request={investorsRequests.CREATE_INVESTOR}
          />
        </Container>
      </Content>
    </>
  );
};

export default NewInvestor;
