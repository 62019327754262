import React from 'react';
import * as S from './Form.styles';
import Input from 'components/core/form/Input';
import Button from 'components/core/Button';
import HandleMessage from 'components/core/HandleMessage';
import {isPending, isReject} from 'lib/core/request';
import Loading from 'components/core/Loading';
const Form = ({register, handleSubmit, error, errors, request, onSubmit}) => {
  return (
    <S.FormContainer onSubmit={handleSubmit(onSubmit)}>
      {isReject(request) && (
        <S.FormGroup>
          <HandleMessage type="error">{error.data.message}</HandleMessage>
        </S.FormGroup>
      )}
      <Input
        inputRef={register}
        label="Investor's name"
        type="text"
        name="name"
        errors={errors.name && errors.name.message}
      />
      <Input
        inputRef={register}
        label="E-mail"
        type="email"
        name="email"
        errors={errors.email && errors.email.message}
      />

      <Button type="submit" color="primary" disabled={isPending(request)}>
        {isPending(request) ? <Loading /> : 'Register'}
      </Button>
    </S.FormContainer>
  );
};

export default Form;
