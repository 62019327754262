const urls = {
  ROUTES: {
    APP: '/',
    LOGIN: '/login',
    DASHBOARD: '/',
    ENTERPRISES: '/enterprises',
    CREATE_ENTERPRISE: '/enterprises/new',
    INVESTORS: '/investors',
    CREATE_INVESTOR: '/investors/new',
    OFFERS: '/offers',
  },
  LINKS: {
    APP: '/',
    LOGIN: '/login',
    DASHBOARD: '/',
    ENTERPRISES: '/enterprises',
    CREATE_ENTERPRISE: '/enterprises/new',
    INVESTORS: '/investors',
    CREATE_INVESTOR: '/investors/new',
    OFFERS: '/offers',
  },
};

export default urls;
