import {takeLatest, put} from 'redux-saga/effects';
import api from 'services/api';
import {RESOURCE_OFFERS} from 'constants/endpoints';
import {GET} from 'constants/verbs';
import {serialize} from 'lib/core/utility';
import {OffersActions, OffersTypes} from './offers.ducks';

export function* listOffers({params}) {
  try {
    const response = yield api({
      method: GET,
      url: `${RESOURCE_OFFERS}?${serialize(params)}`,
    });
    yield put(OffersActions.listOffersSuccess(response.data));
  } catch (error) {
    yield put(OffersActions.offersRequestFailure(error));
  }
}

export function* watchSagas() {
  yield takeLatest(OffersTypes.LIST_OFFERS, listOffers);
}
