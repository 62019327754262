import {takeLatest, put} from 'redux-saga/effects';
import api from 'services/api';
import {RESOURCE_ENTERPRISES} from 'constants/endpoints';
import {GET, POST} from 'constants/verbs';
import {serialize} from 'lib/core/utility';
import {EnterprisesActions, EnterprisesTypes} from './enterprises.ducks';
import {history} from 'constants/routes';
import urls from 'constants/urls';

export function* listEnterprises({params}) {
  try {
    const response = yield api({
      method: GET,
      url: `${RESOURCE_ENTERPRISES}?${serialize(params)}`,
    });
    yield put(EnterprisesActions.listEnterprisesSuccess(response.data));
  } catch (error) {
    yield put(EnterprisesActions.enterprisesRequestFailure(error));
  }
}

export function* createEnterprise({payload, type}) {
  try {
    yield api({
      method: POST,
      url: RESOURCE_ENTERPRISES,
      data: payload,
    });

    yield put(EnterprisesActions.createEnterpriseSuccess());
    yield history.push(urls.LINKS.ENTERPRISES);
  } catch (error) {
    yield put(EnterprisesActions.enterprisesRequestFailure(error, type));
  }
}

export function* rankingEnterprise({params}) {
  try {
    const response = yield api({
      method: GET,
      url: `${RESOURCE_ENTERPRISES}/ranking?${serialize(params)}`,
    });
    yield put(EnterprisesActions.rankingEnterpriseSuccess(response.data));
  } catch (error) {
    yield put(EnterprisesActions.enterprisesRequestFailure(error));
  }
}

export function* watchSagas() {
  yield takeLatest(EnterprisesTypes.LIST_ENTERPRISES, listEnterprises);
  yield takeLatest(EnterprisesTypes.CREATE_ENTERPRISE, createEnterprise);
  yield takeLatest(EnterprisesTypes.RANKING_ENTERPRISE, rankingEnterprise);
}
