import React from 'react';
import * as S from './Header.styles';
import Button from 'components/core/Button';
import {ReactComponent as IconBack} from 'assets/icons/back.svg';

const HeaderBack = ({title, buttonText, onClick, onClickBack, showButton}) => {
  return (
    <S.Grid>
      <S.ContainerBack onClick={onClickBack}>
        <IconBack />
        <S.TitleBack> Back </S.TitleBack>
      </S.ContainerBack>
      <S.Center>
        <S.Title>{title}</S.Title>
      </S.Center>
      {showButton && (
        <Button color="primary" width="auto" onClick={onClick}>
          {buttonText}
        </Button>
      )}
    </S.Grid>
  );
};
const HeaderMain = ({title, subtitle, buttonText, onClick}) => {
  return (
    <S.Container>
      <div>
        <S.Title>{title}</S.Title>
        <S.SubTitle>{subtitle}</S.SubTitle>
      </div>
      <Button color="primary" width="auto" onClick={onClick}>
        {buttonText}
      </Button>
    </S.Container>
  );
};
const Header = ({
  title,
  subtitle,
  buttonText,
  onClick,
  type,
  showButton,
  onClickBack,
}) => {
  const renderHeader = () => {
    if (type === 'back') {
      return HeaderBack({
        title,
        buttonText,
        onClick,
        showButton,
        onClickBack,
      });
    } else {
      return HeaderMain({title, subtitle, buttonText, onClick});
    }
  };
  return renderHeader();
};
Header.defaultProps = {
  onClick: () => {},
};
export default Header;
