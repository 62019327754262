import React, {useCallback} from 'react';
import Table from 'components/core/Table';
import * as S from './Table.styles';
import {useDispatch, useSelector} from 'react-redux';
import LoadingFullScreen from 'components/core/LoadingFullScreen/';
import {isPending} from 'lib/core/request';
import {EnterprisesActions} from 'store/enterprises/enterprises.ducks';
import {formatMoney} from 'lib/core/money';

const TableDefault = () => {
  const dispatch = useDispatch();

  const {
    listEnterprises: {
      data: items,
      meta: {totalPages},
    },
    enterprisesRequests,
  } = useSelector(({enterprises}) => enterprises);

  const fetchData = useCallback(
    ({pageSize, pageIndex}) => {
      dispatch(
        EnterprisesActions.listEnterprises({
          limit: pageSize,
          page: pageIndex + 1,
        }),
      );
    },
    [dispatch],
  );

  const columns = React.useMemo(
    () => [
      {
        Header: `ID`,
        accessor: 'id',
        maxWidth: 20,
      },
      {
        Header: `Enterprise's name`,
        accessor: 'enterprise_name',
        minWidth: 200,
      },
      {
        Header: 'E-mail',
        accessor: 'email_enterprise',
        minWidth: 150,
      },
      {
        Header: 'Value',
        accessor: 'enterprise_ranking',
        minWidth: 100,
        Cell: (props) => formatMoney(props.value),
      },
    ],
    [],
  );

  return (
    <S.TableContent>
      {isPending(enterprisesRequests.LIST_ENTERPRISES) && <LoadingFullScreen />}
      <Table
        columns={columns}
        data={items}
        pageCount={totalPages}
        fetchData={fetchData}
        showTitle="enterprises"
      />
    </S.TableContent>
  );
};

export default TableDefault;
