import ENV from 'constants/env';

const base = ENV.URL;
// const base = 'http://sarun-hml.us-east-1.elasticbeanstalk.com';
// const service = "api";
// const version = "v1";
// const prefix = "";

export const basePhoto = 'https://dexp70qwz39bv.cloudfront.net/';

export const basePath = `${base}/`;
export const baseMock = 'https://run.mocky.io/v3/';
export const SIGN_IN = `admin/auth/sign_in`;
export const RESOURCE_OFFERS = 'admin/offers';
export const RESOURCE_DASHBOARDS = 'admin/dashboard';
export const RESOURCE_INVESTORS = 'admin/investors';
export const RESOURCE_ENTERPRISES = 'admin/enterprises';
