import React from 'react';
import {Route, Switch, Redirect} from 'react-router';
import {ConnectedRouter} from 'connected-react-router';
import {createBrowserHistory} from 'history';

import isAuthenticated from 'lib/core/isAuthenticated';
import urls from 'constants/urls';
import Auth from 'pages/auth';
import Enterprises from 'pages/enterprises';
import NewEnterprise from 'pages/new-enterprise';
import Investors from 'pages/investors';
import NewInvestor from 'pages/new-investor';
import Offers from 'pages/offers';
import Dashboard from 'pages/dashboard';
export const history = createBrowserHistory();
history.listen(() => {
  window.scrollTo(0, 0);
});

const PrivateRoute = ({component: Component, ...rest}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: urls.ROUTES.LOGIN,
              state: {from: props.location},
            }}
          />
        )
      }
    />
  );
};

const Routes = () => (
  <ConnectedRouter history={history}>
    <Switch>
      <Route exact path={urls.ROUTES.LOGIN} component={Auth} />
      <PrivateRoute exact path={urls.ROUTES.APP} component={Dashboard} />
      <PrivateRoute
        exact
        path={urls.ROUTES.ENTERPRISES}
        component={Enterprises}
      />
      <PrivateRoute
        exact
        path={urls.ROUTES.CREATE_ENTERPRISE}
        component={NewEnterprise}
      />
      <PrivateRoute exact path={urls.ROUTES.INVESTORS} component={Investors} />
      <PrivateRoute
        exact
        path={urls.ROUTES.CREATE_INVESTOR}
        component={NewInvestor}
      />
      <PrivateRoute exact path={urls.ROUTES.OFFERS} component={Offers} />
    </Switch>
  </ConnectedRouter>
);

PrivateRoute.propTypes = {};

PrivateRoute.defaultProps = {};

export default Routes;
