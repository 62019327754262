import React from 'react';
import {NavbarDefault} from 'components/core/navbar';
import {Container} from 'components/structure/Container';
import {Content} from 'components/structure/Content';
import Dashboard from 'components/contexts/dashboard/Dashboard';
import TableEnterpriseRanking from 'components/contexts/dashboard/TableEnterpriseRanking';
import TableInvestorRanking from 'components/contexts/dashboard/TableInvestorRanking';

const DashboardPage = () => {
  return (
    <>
      <NavbarDefault active="dashboard" />
      <Content>
        <Container>
          <Dashboard />
          <TableEnterpriseRanking />
          <TableInvestorRanking />
        </Container>
      </Content>
    </>
  );
};

export default DashboardPage;
