import {takeLatest, put} from 'redux-saga/effects';
import api from 'services/api';
import {RESOURCE_INVESTORS} from 'constants/endpoints';
import {GET, POST} from 'constants/verbs';
import {serialize} from 'lib/core/utility';
import {InvestorsActions, InvestorsTypes} from './investors.ducks';
import urls from 'constants/urls';
import {history} from 'constants/routes';

export function* listInvestors({params}) {
  try {
    const response = yield api({
      method: GET,
      url: `${RESOURCE_INVESTORS}?${serialize(params)}`,
    });
    yield put(InvestorsActions.listInvestorsSuccess(response.data));
  } catch (error) {
    yield put(InvestorsActions.investorsRequestFailure(error));
  }
}

export function* createInvestor({payload, type}) {
  try {
    yield api({
      method: POST,
      url: RESOURCE_INVESTORS,
      data: payload,
    });

    yield put(InvestorsActions.createInvestorSuccess());
    yield history.push(urls.LINKS.INVESTORS);
  } catch (error) {
    yield put(InvestorsActions.investorsRequestFailure(error, type));
  }
}

export function* ranking({params}) {
  try {
    const response = yield api({
      method: GET,
      url: `${RESOURCE_INVESTORS}/ranking?${serialize(params)}`,
    });
    yield put(InvestorsActions.rankingSuccess(response.data));
  } catch (error) {
    yield put(InvestorsActions.investorsRequestFailure(error));
  }
}

export function* watchSagas() {
  yield takeLatest(InvestorsTypes.LIST_INVESTORS, listInvestors);
  yield takeLatest(InvestorsTypes.CREATE_INVESTOR, createInvestor);
  yield takeLatest(InvestorsTypes.RANKING, ranking);
}
