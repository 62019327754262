import React from 'react';
import * as S from './NavbarDefault.styles';
import LogoIcon from 'assets/images/logo.png';
import DropdownNav from './DropdownNav';
import {AuthActions} from 'store/auth/auth.ducks';
import {history} from 'constants/routes';
import urls from 'constants/urls';
import {useDispatch} from 'react-redux';

const NavbarDefault = ({active}) => {
  const dispatch = useDispatch();
  const signOut = () => {
    dispatch(AuthActions.signOut());
  };
  const menuItens = [
    {
      key: '1',
      handleClick: signOut,
      name: 'Sair',
    },
  ];

  return (
    <S.NavTop>
      <S.LogoContainer>
        <S.Logo src={LogoIcon} alt="Logo" />
      </S.LogoContainer>
      <S.MenuContainer>
        <S.TopNav>
          <S.Menu>
            <S.Item
              className={active === 'dashboard' ? 'active' : ''}
              onClick={() => history.push(urls.LINKS.APP)}>
              Dashboard
            </S.Item>
          </S.Menu>
          <S.Menu>
            <S.Item
              className={active === 'enterprises' ? 'active' : ''}
              onClick={() => history.push(urls.LINKS.ENTERPRISES)}>
              Enterprises
            </S.Item>
          </S.Menu>
          <S.Menu>
            <S.Item
              className={active === 'investors' ? 'active' : ''}
              onClick={() => history.push(urls.LINKS.INVESTORS)}>
              Investors
            </S.Item>
          </S.Menu>
          <S.Menu>
            <S.Item
              className={active === 'offers' ? 'active' : ''}
              onClick={() => history.push(urls.LINKS.OFFERS)}>
              Offers
            </S.Item>
          </S.Menu>
        </S.TopNav>
      </S.MenuContainer>
      <S.UserContainer>
        <DropdownNav
          userType=""
          username="Administrador"
          menuItens={menuItens}
        />
      </S.UserContainer>
    </S.NavTop>
  );
};

export default NavbarDefault;
