import React, {useCallback} from 'react';
import Table from 'components/core/Table';
import * as S from './TableUsers.styles';
import {useDispatch, useSelector} from 'react-redux';
import {Search} from 'components/core/form/Search';
import LoadingFullScreen from 'components/core/LoadingFullScreen/';
import {isPending} from 'lib/core/request';
import {EnterprisesActions} from 'store/enterprises/enterprises.ducks';
import {formatMoney} from 'lib/core/money';

const TableEnterprises = () => {
  const dispatch = useDispatch();
  const {
    ranking: {
      data: items,
      meta: {totalPages},
    },
    enterprisesRequests,
  } = useSelector(({enterprises}) => enterprises);

  const onChangeSearch = (e) => {
    dispatch(
      EnterprisesActions.rankingEnterprise({
        name: e.target.value,
        limit: 10,
        page: 1,
      }),
    );
  };

  const fetchData = useCallback(
    ({pageSize, pageIndex}) => {
      dispatch(
        EnterprisesActions.rankingEnterprise({
          limit: pageSize,
          page: pageIndex + 1,
        }),
      );
    },
    [dispatch],
  );

  const columns = React.useMemo(
    () => [
      {
        Header: `Enterprise's name`,
        accessor: 'enterprise_name',
        minWidth: 200,
      },
      {
        Header: 'Shares',
        accessor: 'shares',
        minWidth: 100,
      },
      {
        Header: 'Share Price',
        accessor: 'share_price',
        minWidth: 100,
        Cell: (props) => formatMoney(props.value),
      },
      {
        Header: 'Balance',
        accessor: 'enterprise_ranking',
        minWidth: 100,
        Cell: (props) => formatMoney(props.value),
      },
    ],
    [],
  );

  return (
    <S.TableContent>
      {isPending(enterprisesRequests.RANKING) && <LoadingFullScreen />}
      <S.Header>
        <S.Title>Enterprise Ranking</S.Title>
        <S.FilterContainer>
          <Search placeholder="Search enterprise" onChange={onChangeSearch} />
        </S.FilterContainer>
      </S.Header>
      <Table
        columns={columns}
        data={items}
        pageCount={totalPages}
        fetchData={fetchData}
      />
    </S.TableContent>
  );
};

export default TableEnterprises;
