import {createGlobalStyle} from 'styled-components';
import tokens from 'assets/styles/tokens';

const GlobalStyle = createGlobalStyle` 
  * {    
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }
  body {
    margin:0;
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    background: ${tokens.colors.background};
  }
`;
export default GlobalStyle;
