import {all} from 'redux-saga/effects';
import * as authSagas from './auth/auth.sagas';
import * as enterprisesSagas from './enterprises/enterprises.sagas';
import * as dashboardSagas from './dashboard/dashboard.sagas';
import * as investorsSagas from './investors/investors.sagas';
import * as offersSagas from './offers/offers.sagas';

function* Sagas() {
  yield all([
    authSagas.watchSagas(),
    enterprisesSagas.watchSagas(),
    dashboardSagas.watchSagas(),
    investorsSagas.watchSagas(),
    offersSagas.watchSagas(),
  ]);
}

export default Sagas;
