import React, {useCallback} from 'react';
import Table from 'components/core/Table';
import * as S from './TableUsers.styles';
import {useDispatch, useSelector} from 'react-redux';
import {Search} from 'components/core/form/Search';
import LoadingFullScreen from 'components/core/LoadingFullScreen/';
import {isPending} from 'lib/core/request';
import {InvestorsActions} from 'store/investors/investors.ducks';
import {formatMoney} from 'lib/core/money';

const TableInvestors = () => {
  const dispatch = useDispatch();
  const {
    ranking: {
      data: items,
      meta: {totalPages},
    },
    investorsRequests,
  } = useSelector(({investors}) => investors);

  const onChangeSearch = (e) => {
    dispatch(
      InvestorsActions.ranking({name: e.target.value, limit: 10, page: 1}),
    );
  };

  const fetchData = useCallback(
    ({pageSize, pageIndex}) => {
      dispatch(
        InvestorsActions.ranking({limit: pageSize, page: pageIndex + 1}),
      );
    },
    [dispatch],
  );

  const columns = React.useMemo(
    () => [
      {
        Header: `Investor's name`,
        accessor: 'investor_name',
        minWidth: 200,
      },
      {
        Header: 'Balance',
        accessor: 'balance',
        minWidth: 100,
        Cell: (props) => formatMoney(props.value),
      },
      {
        Header: 'Portfolio Value',
        accessor: 'investor_ranking',
        minWidth: 100,
        Cell: (props) => formatMoney(props.value),
      },
    ],
    [],
  );

  return (
    <S.TableContent>
      {isPending(investorsRequests.RANKING) && <LoadingFullScreen />}
      <S.Header>
        <S.Title>Investor Ranking</S.Title>
        <S.FilterContainer>
          <Search placeholder="Search investor" onChange={onChangeSearch} />
        </S.FilterContainer>
      </S.Header>
      <Table
        columns={columns}
        data={items}
        pageCount={totalPages}
        fetchData={fetchData}
      />
    </S.TableContent>
  );
};

export default TableInvestors;
