const tokens = {
  colors: {
    background: '#F2F4F7',
    slimeGreen: '#9bde0c',
    midnightBlue48: 'rgba(18, 0, 56, 0.48)',
    whiteTwo: '#e5e5e5',
    whiteLight: '#f9f9f9',
    white: '#ffffff',
    midnight: '#0b0015',
    paleGrey: '#ebebec',
    purple: '#9C268F',
    purpleLight: '#F3E5F2',
    midnight8: 'rgba(11, 0, 21, 0.08)',
    darkLime: '#83b400',
    greenishCyan: '#32ff9d',
    veryLightPink: '#fff3f3',
    orange01: '#FF8A00',
    orange: '#F2682A',
    red01: '#FF175D',
    blue01: '#1774FF',
    gray01: '#ECECF3',
    gray02: '#82829D',
    white24: 'rgba(255, 255, 255, 0.24)',
    darkGrey8: 'rgba(29, 29, 32, 0.08)',
    turquoiseGreen: '#00e794',
    black01: '#0B0015',
    black02: '#4F4757',
    black03: '#9D99A1',
    green01: '#9BDE0C',
    green02: '#63B000',
    greenMessage: 'rgba(0, 138, 88, 0.16)',
    greenTextMessage: '#008A58',
    redTextMessage: '#6f1616',
    redMessage: 'rgba(255, 0, 0, 0.16)',
    primaryColor: '#FF004E',
    primaryBackgroundColor: '#182540',
    primaryHeaderColor: '#00003d',
  },
  fonts: {
    regular: 'DM Sans',
    medium: 'DM Sans',
    bold: 'DM Sans',
    semibold: 'DM Sans',
  },
  media: {
    xs: '@media (max-width: 480px)',
    sm: '@media (max-width: 768px)',
    md: '@media (max-width: 1024px)',
    lg: '@media (min-width: 1280px)',
    xl: '@media (min-width: 1600px)',
    xxl: '@media (min-width: 1920px)',
    breakpoints: {
      xs: 480,
      sm: 768,
      md: 1024,
      lg: 1280,
      xl: 1600,
      xxl: 1920,
    },
    mobile: '@media only screen and (max-width: 480px)',
    tablet: '@media (min-width: 480px) and (max-width: 768px)',
    desktop: '@media (min-width: 768px)',
  },
  breakpoints: ['320px', '768px', '1500px', '1920px'],
  spacing: {
    xs: '4px',
    sm: '8px',
    md: '16px',
    lg: '24px',
    xss: '36px',
  },
};

export default tokens;
