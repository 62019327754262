import styled from "styled-components";
import tokens from "assets/styles/tokens";

export const FormContainer = styled.form`
  width: 450px;
  height: 300px;
  margin: auto;
`;
export const FormGroup = styled.div`
  margin-top: ${tokens.spacing.lg};
  margin-bottom: ${tokens.spacing.lg};
`;
