import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import auth from './auth/auth.ducks';
import enterprises from './enterprises/enterprises.ducks';
import message from './message/message.ducks';
import dashboard from './dashboard/dashboard.ducks';
import investors from './investors/investors.ducks';
import offers from './offers/offers.ducks';

const appReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    enterprises,
    message,
    dashboard,
    investors,
    offers,
  });

const Reducers = (history) => appReducer(history);

export default Reducers;
