import React, {useEffect} from 'react';
import * as S from './Dashboard.styles';
import {useDispatch, useSelector} from 'react-redux';

import {Row} from 'components/structure/Row';
import {Col} from 'components/structure/Col';
import {CardInfo, CardTitle, CardDescription} from 'components/core/CardInfo';
import {DashboardActions} from 'store/dashboard/dashboard.ducks';

const Dashboard = () => {
  const dispatch = useDispatch();
  const {data} = useSelector(({dashboard}) => dashboard);
  useEffect(() => {
    dispatch(DashboardActions.getDashboards());
  }, [dispatch]);

  return (
    <S.DashboardContent>
      <S.Header>
        <S.Title>Dashboard</S.Title>
      </S.Header>
      <S.WrapperCard>
        <Row>
          <Col desktop="4" mobile="12">
            <CardInfo>
              <CardTitle color="blue">{data?.countInvestors || '-'}</CardTitle>
              <CardDescription>Number of investors</CardDescription>
            </CardInfo>
          </Col>
          <Col desktop="4" mobile="12">
            <CardInfo>
              <CardTitle color="blue">
                {data?.countEnterprises || '-'}
              </CardTitle>
              <CardDescription>Number of enterprises</CardDescription>
            </CardInfo>
          </Col>
          <Col desktop="4" mobile="12">
            <CardInfo>
              <CardTitle color="blue">
                {data?.countOffersAccepted || '-'}
              </CardTitle>
              <CardDescription>Total offers accepted</CardDescription>
            </CardInfo>
          </Col>
        </Row>
      </S.WrapperCard>
    </S.DashboardContent>
  );
};

export default Dashboard;
