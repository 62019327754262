import React from 'react';
import {NavbarDefault} from 'components/core/navbar';
import {Container} from 'components/structure/Container';
import {Content} from 'components/structure/Content';
import Table from 'components/contexts/offers/Table';

const Offers = () => {
  return (
    <>
      <NavbarDefault active="offers" />
      <Content>
        <Container>
          <Table />
        </Container>
      </Content>
    </>
  );
};

export default Offers;
