import React, {useCallback} from 'react';
import Table from 'components/core/Table';
import * as S from './Table.styles';
import {useDispatch, useSelector} from 'react-redux';
import LoadingFullScreen from 'components/core/LoadingFullScreen/';
import {isPending} from 'lib/core/request';
import {OffersActions} from 'store/offers/offers.ducks';
import {formatMoney} from 'lib/core/money';
import moment from 'moment';

const TableDefault = () => {
  const dispatch = useDispatch();

  const {
    listOffers: {
      data: items,
      meta: {totalPages, itemsPerPage, currentPage},
    },
    offersRequests,
  } = useSelector(({offers}) => offers);

  const fetchData = useCallback(
    ({pageSize, pageIndex}) => {
      dispatch(
        OffersActions.listOffers({
          limit: pageSize,
          page: pageIndex + 1,
        }),
      );
    },
    [dispatch],
  );

  const columns = React.useMemo(
    () => [
      {
        Header: `ID`,
        accessor: 'id',
        maxWidth: 20,
      },
      {
        Header: `Investor's name`,
        accessor: 'investor.investor_name',
      },
      {
        Header: `Enterprise's name`,
        accessor: 'enterprise.enterprise_name',
      },
      {
        Header: 'Value',
        accessor: 'price',
        Cell: (props) => formatMoney(props.value),
      },
      {
        Header: 'Shares',
        accessor: 'shares',
        maxWidth: 20,
      },
      {
        Header: 'Status',
        accessor: 'accepted',
        Cell: (props) =>
          props.value === null
            ? 'Pending'
            : props.value
            ? 'Accepted'
            : 'Rejected',
        maxWidth: 20,
      },
      {
        Header: 'Created at',
        accessor: 'created_at',
        Cell: (props) => moment(props.value).format('YYYY-MM-DD HH:MM:SS'),
        minWidth: 200,
      },
      {
        Header: 'Updated at',
        accessor: 'updated_at',
        Cell: (props) => moment(props.value).format('YYYY-MM-DD HH:MM:SS'),
        minWidth: 200,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [itemsPerPage, currentPage, dispatch],
  );

  return (
    <S.TableContent>
      {isPending(offersRequests.LIST_ENTERPRISES) && <LoadingFullScreen />}
      <S.Header>
        <S.Title>Offers </S.Title>
      </S.Header>
      <Table
        columns={columns}
        data={items}
        pageCount={totalPages}
        fetchData={fetchData}
        showTitle="offers"
      />
    </S.TableContent>
  );
};

export default TableDefault;
