import styled from 'styled-components';
import tokens from 'assets/styles/tokens';

export const Container = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: space-between;
  margin-bottom: 54px;
`;

export const ContainerBack = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Title = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: ${tokens.colors.black01};
`;
export const SubTitle = styled.h2`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${tokens.colors.black01};
`;

export const Grid = styled.div`
  display: grid;
  margin-top: 40px;
  grid-template-columns: 1fr 3fr 1fr;
  margin-bottom: 70px;
`;

export const Center = styled.div`
  text-align: center;
`;
export const TitleBack = styled.h2`
  font-style: normal;
  font-weight: 500;
  margin-left: ${tokens.spacing.sm};
  font-size: 16px;
  line-height: 24px;
  color: ${tokens.colors.black01};
`;
